<template>
  <v-card>
    <v-card-text>
      <slot name="title"></slot>
      <v-row class="align-center">
        <v-col cols="3">
          <div>
            <slot name="subtitle"></slot>
            <span class="body-1">{{ total }}</span>
          </div>
        </v-col>
        <v-col cols="2">
          <div>
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="params.date_to"
                  label="Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="ml-5 mr-2 rounded-lg"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                @input="endDateMenu = false"
                v-model="params.date_to"
                @change="setFilters()"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="loading">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
        <v-col v-else>
          <bar-chart
            :key="componentKey"
            :chartdata="chartData"
            :options="chartOptions"
          />
          <div>
            {{ totalForTheLastNDays }} for the last {{ numberOfDays }} days
          </div>
          <div>
            <div v-for="(location, key) in locations" :key="key">
              {{ location.name }}: {{ location.count }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

const COLORS = ["red", "green", "blue", "purple", "orange", "black"];

export default {
  // extends: Line,
  components: {
    BarChart: () => import("./BarChart.vue")
  },
  props: {
    locations: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    params: {
      date_from: null,
      date_to: null
    },
    endDateMenu: false,
    numberOfDays: 10,
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false
    },
    chartData2: {},
    componentKey: 0
  }),
  computed: {
    chartData() {
      return this.chartData2;
    },
    totalForTheLastNDays() {
      return this.locations.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count;
      }, 0);
    }
  },
  watch: {
    data: {
      handler: function(value) {
        if (value.length) {
          let dates = value.map(item => {
            return {
              id: item.id,
              locationId: item.user.location_id,
              date: moment(item.created_at).format("YYYY-MM-DD")
            };
          });

          const dateTo = this.params.date_to;
          let dateRange = [];

          for (let index = 0; index < this.numberOfDays; index++) {
            let date = moment(dateTo)
              .subtract(index, "days")
              .format("YYYY-MM-DD");

            dateRange.push(date);
          }

          this.chartData2.datasets = this.locations.map((item, key) => {
            let dateCounts = [];
            dateRange.forEach(range => {
              dateCounts.push(
                dates.filter(
                  date => date.locationId == item.id && date.date === range
                ).length
              );
            });

            return {
              label: item.name,
              backgroundColor: COLORS[key],
              data: dateCounts
            };
          });

          this.chartData2.labels = dateRange;

          this.componentKey++;
        } else {
          const dateTo = this.params.date_to;
          let dateRange = [];

          for (let index = 0; index < this.numberOfDays; index++) {
            let date = moment(dateTo)
              .subtract(index, "days")
              .format("YYYY-MM-DD");

            dateRange.push(date);
          }

          this.chartData2.datasets = this.locations.map((item, key) => {
            let dateCounts = [];
            dateRange.forEach(() => {
              dateCounts.push(0);
            });

            dateCounts.push(1); //to start chart value at 0

            return {
              label: item.name,
              backgroundColor: COLORS[key],
              data: dateCounts
            };
          });

          this.chartData2.labels = dateRange;
        }
      },
      immediate: true
    }
  },
  methods: {
    setFilters() {
      const exceptToday = 1;
      this.params.date_from = moment(this.params.date_to)
        .subtract(this.numberOfDays - exceptToday, "days")
        .format("YYYY-MM-DD");
      this.$emit("filter:change", this.params);
      this.componentKey++;
    }
  },
  created() {
    this.params.date_to = moment().format("YYYY-MM-DD");
    this.setFilters();
  },
  mounted() {}
};
</script>
